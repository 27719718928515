jQuery(document).ready(function () {
    if (jQuery('body').hasClass('single-tribe_events')) {
        var hash = window.location.hash;
        if (hash) {
            jQuery('.nav-link[href="' + hash + '"]').tab('show');
            window.location = '#';
        }
    }


    jQuery('#meetingInformationTabs a').click(function (e) {
        //    jQuery(this).tab('show');
        //    window.scrollTo(0,0);
        window.location.hash = this.hash;
    });

    jQuery('#sync-attendance').click(function (e) {
        console.log("Clicked");
        e.preventDefault();
        var meetingId = jQuery(this).attr('data-meeting-id');
        jQuery.post('/util/attendance-sync.php', {
            meeting_id: meetingId
        }, function (data, status) {
            if (status === "success") {
                jQuery('#meeting-result').html(data);
            }
        });

    });
});

